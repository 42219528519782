import React from "react";
import { Image, Tag } from "antd";
import { useHistory } from "react-router-dom";

export default function ProjectDisplay({
  title,
  company,
  role,
  technologies,
  images,
  backgroundColor,
  name,
}) {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/projects/${name}`);
  };

  return (
    <div className="project-display" onClick={handleClick}>
      {images && (
        <div className="project-image-container" style={{ backgroundColor }}>
          <Image
            className="image"
            preview={false}
            src={images[0] || ""}
            alt={`${title}-image`}
          />
        </div>
      )}

      <div className="project-header">
        <h3 className="flex">
          <a href="#" onClick={(e) => e.preventDefault()}>
            {company}
          </a>
          {title}
        </h3>
        <h4>{role}</h4>
        <div>
          {technologies.map(({ name, color }) => (
            <Tag key={name} color={color}>
              {name}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
}
