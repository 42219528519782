import React from "react";
import "../Styles/footer.scss";

export default function Footer() {
  return (
    <footer>
      <section>
        <h5>Running on caffeine and anime marathons.</h5>
        <div>Social media</div>
        <span className="creator">@ Noe Osorio 2024</span>
      </section>
      <section className="links">
        <a href="/projects/osmind">Osmind</a>
        <a href="/projects/clip">Clip</a>
        <a href="/projects/fstack">FullStack</a>
        <a href="/projects/finloop">Finloop</a>
      </section>
      <section className="links">
        <a href="https://blog.noeosorio.com/">Blog</a>
        <a href="/quedirian">Podcast</a>
        <a href="/projects">Projects</a>
        <a href="/contact">Contact</a>
      </section>
    </footer>
  );
}
