import React from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Image, Tag } from "antd";
import { Button } from "../Components/Buttons";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DatabaseOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import portfolioData from "../Data/portfolio.json";
import workData from "../Data/work.json";
import "../Styles/ProjectDetail.scss";

const getProjectData = (name) => portfolioData[name] || workData[name];

const icons = {
  schedule: <CheckCircleOutlined className="icon" />,
  dataSync: <ClockCircleOutlined className="icon" />,
  compliance: <DatabaseOutlined className="icon" />,
  integration: <CodeOutlined className="icon" />,
};

export default function ProjectDetails() {
  const { name } = useParams();
  const project = getProjectData(name);

  if (!project) {
    return <div>Project not found</div>;
  }

  const {
    title,
    description,
    role,
    company,
    companyLink,
    technologies,
    images,
    keyPoints,
    timeline,
  } = project || {};

  return (
    <section className="project-details">
      {/* Hero Section */}
      <Row gutter={[16, 16]} className="hero-section">

        <Col xs={24} sm={24} lg={14} className="hero-content">
          <h1>{title}</h1>
          <span>{role}</span>
          <h5>{description}</h5>
          <div className="hero-tags">
            {technologies.map(({ name, color }) => (
              <Tag key={name} color={color}>
                {name}
              </Tag>
            ))}
          </div>
          <Button
            type="primary"
            target="_blank"
            onClick={() => {
              window.open(
                companyLink,
                "_blank"
              );
            }}
          >
            Visit {company}
          </Button>
        </Col>
        <Col xs={24} sm={24} lg={10} className="hero-image">
          <Image src={images[0]} alt={`${company}-logo`} />
        </Col>
      </Row>

      {/* Key Points Section */}
      <h2 className="key-points-title">Key Points</h2>
      <Row gutter={[16, 16]} className="key-points-cards">
        {keyPoints.map((point, index) => (
          <Col key={index} xs={24} sm={12} lg={12}>
            <Card>
              {icons[point.icon] || <CodeOutlined className="icon" />}
              <h3>{point.title}</h3>
              <p>{point.content}</p>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Timeline Section */}
      <h2 className="timeline-title">Project Timeline</h2>
      <VerticalTimeline>
        {timeline.map((event, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#2E2E2E", color: "#fff" }}
            contentArrowStyle={{ borderRight: `7px solid #2E2E2E` }}
            date={event.date}
            iconStyle={{ background: "#D6F35C", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">{event.title}</h3>
            <p>{event.description}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </section>
  );
}
