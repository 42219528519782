import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
//Layout
import Layout from "../Components/Layout";
import Home from "./Home";
import Contact from "./Contact";
import NotFound from "./NotFound";
import WorkExperience from "./Work";
import Mars from "./Mars";
import PodcastLinks from "./PodcastLinks/PodcastLinks";
import BlogPost from "./Blog/BlogPost";
import AboutMe from "./AboutMe";
import YenesPrivacy from "./Apps/100Yenes/Privacy";
// import Projects from "./Projects";
import Projects from "./Projects.page";
import ProjectDetail from "./ProjectDetail.page";

export default class AppNavigation extends Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={AboutMe} />
          <Route path="/work" exact component={WorkExperience} />
          <Route path="/work/:id" exact component={WorkExperience} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/projects/:name" exact component={ProjectDetail} />
          <Route path="/prices" exact component={NotFound} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/mars" exact component={Mars} />
          <Route path="/quedirian" exact component={PodcastLinks} />
          <Route path="/blog/:id" exact component={BlogPost} />
          <Route path="/apps/100yenes/privacy" exact component={YenesPrivacy} />
          <Route component={NotFound}></Route>
        </Switch>
      </Layout>
    );
  }
}
