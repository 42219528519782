import React from "react";
import "../Styles/buttons.scss";

export function Button(props) {
  return (
    <button
      type={props.type || "submit"}
      className="button"
      onClick={
        props.action ||
        (() => {})
      }
      {...props}
    >
      {props.children || "Button"}
    </button>
  );
}

export function OptionButton(props) {
  return (
    <button
      type={props.type || "submit"}
      className={`option-button ${props.outline ? "outline" : ""} `}
      onClick={
        props.action ||
        (() => {
          console.log("Button");
        })
      }
    >
      {props.children || "Button"}
    </button>
  );
}

export function MediumButton(props) {
  return (
    <button
      type={props.type || "submit"}
      className={`option-button ${props.outline ? "outline" : ""} `}
      onClick={
        props.action ||
        (() => {
          console.log("Button");
        })
      }
    >
      {props.children || "Button"}
    </button>
  );
}
