import workData from "../Data/work.json";
import portfolioData from "../Data/portfolio.json";
import ProjectDisplay from "../Components/ProjectDisplay";
import "../Styles/Work.scss";

export default function Projects() {
  return (
    <>
      {/* Hero Section */}
      <header className="projects-header">
        <h1>
          <b className="txt-primary">Crafting Solutions,</b> One Line of Code at
          a Time.
        </h1>
        <h5>
          Dive into a showcase of projects where <strong>creativity</strong>{" "}
          meets <strong>functionality</strong>. From futuristic web apps to
          game-changing mobile solutions, each project pushes the boundaries of
          what's possible in tech.{" "}
          <strong className="txt-primary">Ready to explore?</strong> Let's go!
        </h5>
      </header>

      {/* Section 1: Personal Projects */}
      <section className="personal-projects">
        <h2>
          Where <strong>Curiosity</strong> Meets <strong>Code</strong>
        </h2>
        <h5>
          These are the projects where I <strong>break the rules</strong>,
          experiment with
          <strong> cutting-edge technologies</strong>, and solve problems in{" "}
          <strong>unique ways</strong>. Here’s a glimpse of what happens when{" "}
          <strong>creativity</strong> is in the driver’s seat.
        </h5>
        <div className="project-container">
          {Object.keys(portfolioData).map((project, index) => (
            <ProjectDisplay
              key={portfolioData[project].title || `personal-project-${index}`}
              name={project}
              {...portfolioData[project]}
            />
          ))}
        </div>
      </section>

      {/* Section 2: Professional Experience */}
      <section className="professional-experience">
        <h2>
          Impacting <strong>Businesses</strong>, One <strong>Solution</strong>{" "}
          at a Time
        </h2>
        <h5>
          In my professional roles, I’ve worked with{" "}
          <strong>talented teams</strong> to deliver
          <strong>robust</strong>, <strong>scalable applications</strong> that
          drive business growth. Each project is a testament to{" "}
          <strong>teamwork</strong> and problem-solving at its finest.
        </h5>
        <div className="project-container">
          {Object.keys(workData).map((project, index) => (
            <ProjectDisplay
              key={workData[project].title || `project-${index}`}
              name={project}
              {...workData[project]}
            />
          ))}
        </div>
      </section>
    </>
  );
}
